$(() => {
  $('.header-nav-item').removeClass('active');
  $('#home').addClass('active');
});

$(() => {
  const normalLogo = 'normal-logo';
  const immerseLogo = 'immerse-logo';
  const height = $('#js-header').offset().top + $('#js-header').height();
  const $navList = $('.navbar-collapse');
  if ($(window).scrollTop() > height) {
    $('#js-header').addClass('home-normal-header');
    $('#js-header').removeClass('immerse-header');
    $(`.${normalLogo}`).show();
    $(`.${immerseLogo}`).hide();
  }
  else {
    $('#js-header').addClass('immerse-header');
    $('#js-header').removeClass('home-normal-header');
    $(`.${immerseLogo}`).show();
    $(`.${normalLogo}`).hide();
  }

  $(window).on('scroll', () => {
    if ($(window).scrollTop() > height) {
      $('#js-header').addClass('home-normal-header');
      $('#js-header').removeClass('immerse-header');
      if ($navList.hasClass('show')) {
        $('.js-mega-menu').removeClass('btn-show-header');
      }
      $(`.${normalLogo}`).show();
      $(`.${immerseLogo}`).hide();
    }
    else {
      $('#js-header').addClass('immerse-header');
      $('#js-header').removeClass('home-normal-header');
      if ($navList.hasClass('show')) {
        $('.js-mega-menu').addClass('btn-show-header');
      }
      $(`.${immerseLogo}`).show();
      $(`.${normalLogo}`).hide();
    }
  });

  $('.navbar-toggler').on('click', () => {
    const $header = $('.new-header');
    if ($header.hasClass('immerse-header')) {
      if (!$navList.hasClass('show')) {
        $('.js-mega-menu').addClass('btn-show-header');
      }
      else {
        $('.js-mega-menu').removeClass('btn-show-header');
      }
    }
  });
});

// 用户角色的显示
$(() => {
  // - const delay = 300;
  const delay = 0;
  $('.home-space-section-nav-item').on('click', (e) => {
    $('.home-space-section-nav-item').removeClass('active');
    $(e.target).addClass('active');
    const navType = $(e.target).attr('nav');
    const currentContent = $(`.home-space-section-nav-item-content[nav=${navType}]`);
    if (currentContent && currentContent.length) {
      // - $('.home-space-section-nav-item-content').addClass('hide');
      // - currentContent.removeClass('hide');
      $('.home-space-section-nav-item-content').hide(delay);
      currentContent.show(delay);
    }
  });
  // 默认点击第一个。
  $('.home-space-section-nav-item').eq(0).click();
});

// 更改导航栏
// $(() => {
//   $('.new-header').addClass('immerse-header');
//   $('.normal-logo').hide();
//   $('.immerse-logo').show();
// });

// $(() => {
//   let initScrollDirection = false; // 辅助判断是否反方向滚动
//   const bannerHight = $('.home-banner').height();
//   let isScroll = false; // 是否进入滚动
//   const height = bannerHight + $('.home-banner').offset().top;
//   let isScrollReverse = false; // 是否反方向滚动，防止抖动
//   document.addEventListener('wheel', (e) => {
//     if (height <= $(window).scrollTop()) {
//       return false;
//     }
//     const wheelY = e.deltaY;
//     if (!isScroll) {
//       isScroll = true;
//       if (0 < wheelY) {
//         isScrollReverse = true;
//         if (height > $(window).scrollTop()) {
//           $('html, body').animate({
//             scrollTop: bannerHight,
//           }, 500, 'swing', () => {
//             isScroll = false;
//           });
//         }
//       }
//       else if (0 > wheelY) {
//         isScrollReverse = false;
//         if (height > $(window).scrollTop()) {
//           $('html, body').animate({
//             scrollTop: 0,
//           }, 500, 'swing', () => {
//             isScroll = false;
//             $('.new-header').addClass('immerse-header');
//             $('.new-header').removeClass('home-normal-header');
//             $('.normal-logo').hide();
//             $('.immerse-logo').show();
//           });
//         }
//       }
//       else {
//         isScrollReverse = false;
//       }
//       if (initScrollDirection !== isScrollReverse) { // 滚动方向相反则取消滚动，不然会抖
//         e.preventDefault();
//       }
//       initScrollDirection = isScrollReverse;
//     }
//     else {
//       e.preventDefault();
//     }
//   });
//   $(window).on('scroll', () => {
//     if ($('#js-header').height() <= $(window).scrollTop()) {
//       $('.new-header').removeClass('immerse-header');
//       $('.new-header').addClass('home-normal-header');
//       $('.immerse-logo').hide();
//       $('.normal-logo').show();
//     }
//   });
// });

